import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Modal,
  Typography,
  Popconfirm,
  DatePicker,
  Popover,
  Divider,
  Tabs,
  Tag,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import dayjs from "dayjs";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import ExportReports from "./ExportReports";
const { Text, Title } = Typography;

const Devicereport = () => {
  const [rows, setRows] = useState([]);
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [activeModalTab, setActiveModalTab] = useState(
    "active_devices_not_assigned_to_contract"
  );
  const [modalStates, setModalStates] = useState({
    errors: [],
    warnings: [],
  });
  const getData = async () => {
    setRetrievingData(true);
    try {
      let msp_id = company.id;
      if (scope === "customers") {
        msp_id = company.own_msp.id;
      }
      const response = await api.get("customers/data/devices", {
        params: {
          msp: msp_id,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });
      const data = response.data.map((item) => {
        return {
          ...item,
          state: determineErrorStates(item),
        };
      });
      setRows(data);
    } catch (error) {
      console.error(error);
      messageApi.error("Fehler beim Laden der Daten");
    }
    setRetrievingData(false);
  };

  useEffect(() => {
    if (currentTenant) {
      if (scope == "customers") {
        setCompany(currentTenant.own_company);
      } else if (scope == "msps") {
        const options = currentTenant?.managed_msps;
        setCompanies(options);
      }
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (company && selectedDate) {
      getData();
    }
  }, [company, selectedDate]);
  const setModalErrorStates = (record) => {
    let state = determineErrorStates(record);
    setModalStates(state);
  };
  const showModalData = (record) => {
    setModalErrorStates(record);
    setModalData(record);
    setActiveModalTab("active_devices_not_assigned_to_contract");
    setModalTitle("Details Geräteprüfung " + record.company);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData([]);
    setModalTitle("");
    setModalSubTitle("");
  };
  const determineErrorStates = (value) => {
    let state = {
      errors: [],
      warnings: [],
    };
    if (value.active_devices_not_assigned_to_contract.length > 0) {
      state.errors.push({
        state: "error",
        description:
          "Es existieren aktive Geräte, die keinem Vertrag zugeordnet sind",
      });
    }
    if (value.active_devices_assigned_to_deprecated_contract.length > 0) {
      state.errors.push({
        state: "error",
        description:
          "Es existieren aktive Geräte, die einem abgelaufenen Vertrag zugeordnet sind",
      });
    }
    if (value.inactive_devices_assigned_to_contract.length > 0) {
      state.errors.push({
        state: "error",
        description:
          "Es existieren inaktive Geräte, die einem aktiven Vertrag zugeordnet sind",
      });
    }
    return state;
  };
  const renderState = (state) => {
    let displayState =
      state.errors.length > 0
        ? "error"
        : state.warnings.length > 0
          ? "warning"
          : "success";
    let displayColor =
      displayState === "error"
        ? "red"
        : displayState === "warning"
          ? "orange"
          : "green";
    let displayIcon =
      displayState === "error" ? (
        <WarningOutlinedIcon
          style={{ color: displayColor, marginLeft: "8px" }}
        />
      ) : displayState === "warning" ? (
        <InfoOutlined style={{ color: displayColor, marginLeft: "8px" }} />
      ) : (
        <CheckCircle style={{ color: displayColor, marginLeft: "8px" }} />
      );
    let displayText =
      displayState === "error"
        ? "Fehlerhaft"
        : displayState === "warning"
          ? "Warnung"
          : "Fehlerfrei";
    return { displayColor, displayIcon, displayText };
  };

  const columns = [
    {
      title: "Kunde",
      dataIndex: "company",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.company.localeCompare(b.company),
      defaultSortOrder: "ascend",
    },
    {
      title: "Aktive Geräte",
      dataIndex: "active_devices",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.active_devices.length - b.active_devices.length,
      render: (value) => {
        return value?.length;
      },
    },
    {
      title: "Inaktive Geräte",
      dataIndex: "inactive_devices",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.inactive_devices.length - b.inactive_devices.length,
      render: (value) => {
        return value?.length;
      },
    },
    {
      title: "Status",
      dataIndex: "state",
      sorter: (a, b) => {
        const { displayText: displayTextA } = renderState(a.state);
        const { displayText: displayTextB } = renderState(b.state);
        return displayTextA.localeCompare(displayTextB);
      },
      render: (state, record) => {
        const { displayColor, displayIcon, displayText } = renderState(state);
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: displayColor }}>{displayText}</span>
            </strong>
            {displayIcon}
          </div>
        );
      },
    },

    {
      title: "Details",
      key: "details",
      render: (_, record) => (
        <a onClick={() => showModalData(record)}>Details</a>
      ),
    },
    {
      title: "Aktualisiert",
      dataIndex: "updated",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.ad_data?.updated.localeCompare(b.ad_data?.updated),
      render: (value, record) => {
        return value
          ? dayjs(value).format("DD.MM.YYYY HH:mm")
          : dayjs(record.date).format("DD.MM.YYYY HH:mm");
      },
    },
  ];

  const ModalTag = ({ state }) => {
    let displayText = state.description;
    let target = null;
    const color =
      state.state === "error"
        ? "red"
        : state.state === "warning"
          ? "orange"
          : "success";
    if (
      displayText ===
      "Es existieren aktive Geräte, die keinem Vertrag zugeordnet sind"
    ) {
      target = "active_devices_not_assigned_to_contract";
    } else if (
      displayText ===
      "Es existieren aktive Geräte, die einem abgelaufenen Vertrag zugeordnet sind"
    ) {
      target = "active_devices_assigned_to_deprecated_contract";
    } else if (
      displayText ===
      "Es existieren inaktive Geräte, die einem aktiven Vertrag zugeordnet sind"
    ) {
      target = "inactive_devices_assigned_to_contract";
    }

    return (
      <Tag
        key={state.description}
        icon={
          state.state === "error" ? (
            <WarningIcon style={{ color: "red", margin: 5 }} />
          ) : state.state === "warning" ? (
            <InfoOutlined style={{ color: "orange", margin: 5 }} />
          ) : (
            <CheckCircle style={{ color: "green", margin: 5 }} />
          )
        }
        color={color}
        onClick={() => {
          target && setActiveModalTab(target);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "hidden",
          margin: 5,
          cursor: target ? "pointer" : "default",
        }}
      >
        {displayText}
      </Tag>
    );
  };

  const handleMSPChange = (key, value) => {
    setCompany(value);
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const TabTable = (dataType) => {
    const tableRows = modalData[dataType.dataType]?.map((row) => row);
    const modalColumns = [
      {
        title: "Gerätename",
        dataIndex: "device_name",
      },
      {
        title: "Gerätetyp",
        dataIndex: "device_type",
      },
    ];
    if (dataType.dataType !== "active_devices_not_assigned_to_contract") {
      modalColumns.push({
        title: "Vertragsname",
        dataIndex: "contract_name",
        render: (value) => {
          return value ? value : "N/A";
        },
      });
    }
    return (
      <Table
        columns={modalColumns}
        rowKey="device_name"
        dataSource={tableRows}
        pagination={{
          total: tableRows.length || 0,
          showTotal: (total) => `${total} Objekte`,
          defaultPageSize: 10,
          defaultCurrent: 1,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        scroll={{
          y: 300,
        }}
        loading={{
          spinning: retrievingData,
          indicator: <LoadingOutlined className="spinner-style" />,
        }}
        bordered
      />
    );
  };

  const tabItems = [
    {
      key: "active_devices_not_assigned_to_contract",
      label: "Aktive Geräte ohne Vertrag",
      children: <TabTable dataType="active_devices_not_assigned_to_contract" />,
    },
    {
      key: "inactive_devices_assigned_to_contract",
      label: "Inaktive Geräte aktivem Vertrag zugewiesen",
      children: <TabTable dataType="inactive_devices_assigned_to_contract" />,
    },
    {
      key: "active_devices_assigned_to_deprecated_contract",
      label: "Aktive Geräte veraltetem Vertrag zugewiesen",
      children: (
        <TabTable dataType="active_devices_assigned_to_deprecated_contract" />
      ),
    },
  ];
  const onModalTabChange = (key) => {
    setActiveModalTab(key);
  };
  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={rows}
          scroll={{
            y: 500,
          }}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          bordered
          pagination={false}
          title={() => (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title
                  level={3}
                  style={{
                    marginTop: -10,
                    marginBottom: 10,
                  }}
                >
                  {labels.reports[scope].deviceReports.title}
                </Title>
                <Form
                  size="large"
                  style={{
                    margin: 10,
                  }}
                >
                  <Form.Item>
                    <Space direction="horizontal">
                      <ExportReports
                        selectedDate={selectedDate}
                        selectedReportType="devices"
                      />
                      {scope !== "customers" && (
                        <Select
                          autoFocus={true}
                          showSearch
                          placeholder={
                            labels.reports[scope].contractChecks.placeholder
                              .companies
                          }
                          optionFilterProp="children"
                          onChange={handleMSPChange}
                          style={{
                            width: 320,
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={companies}
                        />
                      )}
                      <DatePicker
                        onChange={onDateChange}
                        picker="month"
                        locale={locale}
                        format={"MMMM YYYY"}
                        style={{ width: 200 }}
                        value={selectedDate}
                      />
                      {contextHolder}
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        />
      </div>

      <Modal
        title="Details Geräteprüfung"
        open={showModal}
        onCancel={closeModal}
        width={1200}
        footer={[
          <Button
            key="back"
            onClick={() => closeModal()}
            className="btn-primary"
          >
            Schließen
          </Button>,
        ]}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "60%",
                flexDirection: "column",
                paddingRight: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", width: "20%" }}>
                  <label>Kunde:</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                  }}
                >
                  {" "}
                  <span>
                    <strong>{modalData?.company}</strong>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", width: "20%" }}>
                  <label>Aktive Geräte:</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                  }}
                >
                  {" "}
                  <span>{modalData?.active_devices?.length}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", width: "20%" }}>
                  <label>Inaktive Geräte:</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                  }}
                >
                  {" "}
                  <span>{modalData?.inactive_devices?.length}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", width: "20%" }}>
                  <label>Erstellt:</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                  }}
                >
                  {" "}
                  <span>
                    {dayjs(modalData?.date).format("DD.MM.YYYY HH:mm")}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", width: "20%" }}>
                  <label>Aktualisiert:</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                  }}
                >
                  {" "}
                  <span>
                    {modalData?.updated
                      ? dayjs(modalData.updated).format("DD.MM.YYYY HH:mm")
                      : dayjs(modalData.date).format("DD.MM.YYYY HH:mm")}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
              }}
            >
              {modalStates.errors.length === 0 &&
                modalStates.warnings.length === 0 && (
                  <ModalTag
                    state={{
                      state: "success",
                      description: "Gerätezuweisungen fehlerfrei",
                    }}
                  />
                )}
              {modalStates.errors.map((state) => (
                <ModalTag state={state} />
              ))}
              {modalStates.warnings.map((state) => (
                <ModalTag state={state} />
              ))}
            </div>
          </div>
          <Divider />
          <Tabs
            items={tabItems}
            activeKey={activeModalTab}
            onChange={onModalTabChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default Devicereport;
