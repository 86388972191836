import "../App.css";
import React, { useRef, useEffect, useState } from "react";
import { Button, Space, Timeline } from "antd";
import api from "../utils/useAxios";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useProvisioningData } from "../store/context";

const UserProvisioningProgress = () => {
  const [steps, setSteps] = useState([]);
  const [pending, setPending] = useState(null);
  const abortFlag = useRef();
  const failedTasks = useRef([]);
  const licenseAssigned = useRef(true);
  const keeperUserCreated = useRef(false);
  const completedTasks = useRef([]);
  const plannedTasks = useRef([]);
  const plannedTasksArray = useRef([]);
  const { ProvisioningData, setProvisioningData } = useProvisioningData();
  const [tasksPlanned, setTasksPlanned] = useState(false);
  const [retryFlag, setRetryFlag] = useState(false);

  const setPlannedTasks = () => {
    if (retryFlag) {
      return;
    }
    if (ProvisioningData.plannedTasks) {
      plannedTasks.current = ProvisioningData.plannedTasks;
      completedTasks.current = ProvisioningData.completedTasks;
      const shortList = ProvisioningData.plannedTasks.map((task) => task.key);
      plannedTasksArray.current = shortList;
      return true;
    }

    let initialPlannedTasks = [
      {
        key: "m365_license",
        label: "M365 Lizenzen zuweisen",
      },
      {
        key: "m365_groups",
        label: "M365 Gruppen zuweisen",
      },
      {
        key: "m365_calendar_visibility",
        label: "M365 Kalendarsichtbarkeit anpassen",
      },
      {
        key: "confluence_user",
        label: "Confluence User erstellen",
      },
      {
        key: "confuence_group",
        label: "Confluence Standort Gruppe zuweisen",
      },
      {
        key: "keeper_user",
        label: "Keeper User erstellen",
      },
      {
        key: "keeper_teams",
        label: "Keeper Teams zuweisen",
      },
      {
        key: "email_marketing",
        label: "Benachrichtigung an Marketing & Franchisemanagement versenden",
      },
      {
        key: "autotask_ticket",
        label: "Autotask Ticket aktualisieren",
      },
    ];
    const keeperTeams = ProvisioningData.employeeAttributes.keeper_teams;
    for (const keeperTeam of keeperTeams) {
      initialPlannedTasks.push({
        key: `keeper_team_${keeperTeam}`,
        label: `Keeper Team ${keeperTeam} zuweisen`,
      });
    }

    const plannedM365Groups = ProvisioningData.employeeAttributes.m365_groups;
    for (const group of plannedM365Groups) {
      initialPlannedTasks.push({
        key: `m365_group_${group}`,
        label: `M365 Gruppe ${group} zuweisen`,
      });
    }
    const plannedM365Licenses =
      ProvisioningData.employeeAttributes.m365_licenses;
    for (const license of plannedM365Licenses) {
      initialPlannedTasks.push({
        key: `m365_license_${license}`,
        label: `${license} Lizenz zuweisen`,
      });
    }

    let systems = ProvisioningData.employeeAttributes.systems;
    if (!Array.isArray(systems)) {
      systems = JSON.parse(systems);
    }
    if (systems.includes("Pipedrive (Vertrieb)")) {
      initialPlannedTasks.push({
        key: "pipedrive_sales_user",
        label: "Pipedrive User im Tenant Vertrieb erstellen",
      });
    }
    if (systems.includes("Pipedrive (Franchisemanagement)")) {
      initialPlannedTasks.push({
        key: "pipedrive_franchise_user",
        label: "Pipedrive User im Tenant Franchisemanagement erstellen",
      });
    }
    if (systems.includes("SAP Business One")) {
      initialPlannedTasks.push({
        key: "sap_user",
        label: "SAP User anfragen",
      });
    }
    if (systems.includes("M365")) {
      initialPlannedTasks.push({
        key: "m365_user",
        label: "M365 User erstellen",
      });
    }
    if (systems.includes("RMM Dashboard")) {
      initialPlannedTasks.push({
        key: "rmm_user",
        label: "RMM User anfragen",
      });
    }
    const company = ProvisioningData.employeeAttributes.company;
    if (company === "einsnulleins Schloß Holte-Stukenbrock") {
      initialPlannedTasks.push({
        key: "email_ITOS",
        label: "Benachrichtigung an ITOS versenden",
      });
    }
    const sharedMailboxes =
      ProvisioningData.employeeAttributes.shared_mailboxes;
    if (!Array.isArray(sharedMailboxes)) {
      sharedMailboxes = JSON.parse(sharedMailboxes);
    }

    if (sharedMailboxes.length > 0) {
      initialPlannedTasks.push({
        key: "m365_shared_mailboxes",
        label: "Berechtigung für geteiltes Postfach erteilen",
      });
      for (const mailbox of sharedMailboxes) {
        initialPlannedTasks.push({
          key: "m365_shared_mailbox_" + mailbox,
          label: `Berechtigung für geteiltes Postfach ${mailbox} erteilen`,
        });
      }
    }
    const shortList = initialPlannedTasks.map((task) => task.key);
    plannedTasksArray.current = shortList;
    plannedTasks.current = initialPlannedTasks;
    console.log(plannedTasks.current);
    setTasksPlanned(true);
    return true;
  };

  const addNewSuccess = (newSuccessElement) => {
    plannedTasks.current = plannedTasks.current.filter(
      (task) => task.key !== newSuccessElement.key
    );
    plannedTasksArray.current = plannedTasksArray.current.filter(
      (task) => task !== newSuccessElement.key
    );
    completedTasks.current = [...completedTasks.current, newSuccessElement];
  };

  const addNewError = (newErrorElement) => {
    plannedTasks.current = plannedTasks.current.filter(
      (task) => task.key !== newErrorElement.key
    );
    plannedTasksArray.current = plannedTasksArray.current.filter(
      (task) => task !== newErrorElement.key
    );
    failedTasks.current = [...failedTasks.current, newErrorElement];
  };

  const saveReport = () => {
    let finished = true;
    if (plannedTasks.current.length > 0) {
      finished = false;
    }
    const protocol = {
      type: "provisioning",
      ticketnumber: ProvisioningData.ticketnumber,
      finished: finished,
      employeeAttributes: ProvisioningData.employeeAttributes,
      plannedTasks: plannedTasks.current,
      completedTasks: completedTasks.current,
      failedTasks: failedTasks.current,
    };
    api.post("reports/useradministration", { data: protocol });
  };

  const setNewStep = (label, content) => {
    if (content.error) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.error,
        color: "red",
        dot: <DangerousIcon sx={{ color: "red" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    } else if (content.warning) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.warning,
        color: "yellow",
        dot: <WarningIcon sx={{ color: "#ffe600" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    } else if (content.success_message) {
      const newStep = {
        label: <strong>{label}</strong>,
        children: content.success_message,
        color: "yellow",
        dot: <CheckCircleIcon sx={{ color: "green" }} />,
      };
      setSteps((prevSteps) => [...prevSteps, newStep]);
    }
  };

  const provisionM365User = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("m365_user")) {
      setPending("Erstelle M365 Benutzer...");
      let response = await api.post("m365/user", {
        params: ProvisioningData.employeeAttributes,
        ticketnumber: ProvisioningData.ticketnumber,
      });
      let result = await response.data;
      setNewStep("M365 Benutzer erstellen", result);
      if (result.error) {
        abortFlag.current = true;
        addNewError({ key: "m365_user", label: "M365 User erstellen" });
        setPending(null);
        setProvisioningData({ ...ProvisioningData, status: "error" });
        setNewStep(<strong>Benutzereingliederung abgebrochen!!!</strong>, {
          error: (
            <strong>
              Da die Erstellung des M365 Users fehlgeschlagen ist, wird die
              Benutzereingliederung abgebrochen
            </strong>
          ),
        });
        return false;
      } else {
        addNewSuccess({ key: "m365_user", label: "M365 User erstellen" });
        return true;
      }
    }
  };

  const assignM365Licenses = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_license")
    ) {
      try {
        let error = false;
        let licenses = ProvisioningData.employeeAttributes.m365_licenses;
        if (!Array.isArray(licenses)) {
          licenses = JSON.parse(licenses);
        }
        for (const license of licenses) {
          if (!plannedTasksArray.current.includes(`m365_license_${license}`)) {
            continue;
          }

          setPending(`Weise ${license} Lizenz zu...`);
          if (abortFlag.current) {
            return;
          }
          const params = {
            params: {
              email: ProvisioningData.employeeAttributes.preferred_mail,
              license: license,
              tenant: ProvisioningData.employeeAttributes.company,
            },
          };
          let result = await api.post("m365/user/licenses", params);
          let data = await result.data;
          setNewStep(`${license} Lizenz zuweisen`, data);

          if (data.error) {
            error = true;
            addNewError({
              key: `m365_license_${license}`,
              label: `${license} Lizenz zuweisen`,
            });
          } else {
            addNewSuccess({
              key: `m365_license_${license}`,
              label: `${license} Lizenz zuweisen`,
            });
          }
        }
        if (error) {
          licenseAssigned.current = false;
          addNewError({
            key: "m365_license",
            label: "M365 Lizenzen zuweisen",
          });
        } else {
          addNewSuccess({
            key: "m365_license",
            label: "M365 Lizenzen zuweisen",
          });
        }
      } catch (error) {
        addNewError({ key: "m365_license", label: "M365 Lizenzen zuweisen" });
        return error;
      }
    }
  };

  const assignM365Groups = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_groups")
    ) {
      try {
        setPending("Weise M365 Gruppen zu...");
        let error = false;
        let groups = ProvisioningData.employeeAttributes.m365_groups;
        for (const group of groups) {
          if (!plannedTasksArray.current.includes(`m365_group_${group}`)) {
            continue;
          }
          setPending(`Weise M365 Gruppe "${group}" zu...`);
          if (abortFlag.current) {
            break;
          }
          const data = {
            groupName: group,
            email: ProvisioningData.employeeAttributes.preferred_mail,
            tenant: ProvisioningData.employeeAttributes.company,
          };
          let result = await api.post("m365/user/groups", data);
          let responseData = await result.data;
          setNewStep(`Gruppe ${group} zuweisen`, responseData);
          if (responseData.error) {
            error = true;
            addNewError({
              key: `m365_group_${group}`,
              label: `M365 Gruppe ${group} zuweisen`,
            });
          } else {
            addNewSuccess({
              key: `m365_group_${group}`,
              label: `M365 Gruppe ${group} zuweisen`,
            });
          }
        }
        if (error) {
          addNewError({ key: "m365_groups", label: "M365 Gruppen zuweisen" });
        } else {
          addNewSuccess({
            key: "m365_groups",
            label: "M365 Gruppen zuweisen",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const assignM365Mailboxes = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_shared_mailboxes")
    ) {
      try {
        if (!licenseAssigned.current) {
          addNewError({
            key: "m365_shared_mailboxes",
            label: "Berechtigung für geteilte Postfächer erteilen",
          });
          setNewStep("Berechtigung für geteiltes Postfach erteilen", {
            error:
              "Da keine M365 Lizenz zugewiesen werden konnte, können auch keine Berechtigungen für geteilte Postfächer erteilt werden.",
          });
          return;
        }
        let error = false;
        let shared_mailboxes =
          ProvisioningData.employeeAttributes.shared_mailboxes;
        if (!Array.isArray(shared_mailboxes)) {
          shared_mailboxes = JSON.parse(shared_mailboxes);
        }
        for (const mailGroup of shared_mailboxes) {
          if (
            !plannedTasksArray.current.includes(
              `m365_shared_mailbox_${mailGroup}`
            )
          ) {
            continue;
          }
          if (abortFlag.current) {
            return;
          }
          setPending(
            `Berechtigung für geteiltes Postfach ${mailGroup} erteilen...`
          );
          const data = {
            tenant: ProvisioningData.employeeAttributes.company,
            user: ProvisioningData.employeeAttributes.preferred_mail,
            mailGroup: mailGroup,
          };
          let groupIdsResponse = await api.post("m365/user/mailgroups", data);
          let responseData = await groupIdsResponse.data;
          setNewStep(
            `Berechtigung für geteiltes Postfach ${mailGroup} erteilen`,
            responseData
          );

          if (responseData.error) {
            error = true;
            addNewError({
              key: `m365_shared_mailbox_${mailGroup}`,
              label: `Berechtigung für geteiltes Postfach ${mailGroup} erteilen`,
            });
          } else {
            addNewSuccess({
              key: `m365_shared_mailbox_${mailGroup}`,
              label: `Berechtigung für geteiltes Postfach ${mailGroup} erteilen`,
            });
          }
        }
        if (error) {
          addNewError({
            key: "m365_shared_mailboxes",
            label: "Berechtigung für geteilte Postfächer erteilen",
          });
        } else {
          addNewSuccess({
            key: "m365_shared_mailboxes",
            label: "Berechtigung für geteilte Postfächer erteilen",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const setCalendarVisibility = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("m365_calendar_visibility")
    ) {
      try {
        if (!licenseAssigned.current) {
          addNewError({
            key: "M365 Calendar Visibility",
            label: "Kalendarsichtbarkeit anpassen",
          });
          setNewStep("Kalendarsichtbarkeit anpassen", {
            error:
              "Da keine M365 Lizenz zugewiesen werden konnte, kann die Kalendarsichtbarkeit nicht angepasst werden.",
          });
          return;
        }
        setPending("Passe Kalendarsichtbarkeit an..");
        const data = {
          tenant: ProvisioningData.employeeAttributes.company,
          user: ProvisioningData.employeeAttributes.preferred_mail,
        };
        let groupIdsResponse = await api.post(
          "m365/user/calendarSettings",
          data
        );
        let responseData = await groupIdsResponse.data;
        setNewStep("Kalendarsichtbarkeit anpassen", responseData);
        if (responseData.error) {
          addNewError({
            key: "m365_calendar_visibility",
            label: "Kalendarsichtbarkeit anpassen",
          });
        } else {
          addNewSuccess({
            key: "m365_calendar_visibility",
            label: "Kalendarsichtbarkeit anpassen",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const provisionConfluenceUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("confluence_user")
    ) {
      setPending("Erstelle Confluence User...");
      const data = {
        email: ProvisioningData.employeeAttributes.preferred_mail,
      };
      let response = await api.post("confluence/user", data);
      let result = await response.data;
      setNewStep("Confluence Benutzer erstellen", result);
      if (result.error) {
        addNewError({
          key: "confluence_user",
          label: "Confluence User erstellen",
        });
      } else {
        addNewSuccess({
          key: "confluence_user",
          label: "Confluence User erstellen",
        });
      }
      return true;
    }
  };

  const assignConfluenceGroup = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("confuence_group")
    ) {
      setPending("Weise Confluence Standort Gruppe zu...");
      const data = {
        company: ProvisioningData.employeeAttributes.company,
        email: ProvisioningData.employeeAttributes.preferred_mail,
      };
      let response = await api.post("confluence/groups", data);
      let result = await response.data;
      setNewStep("Confluence Standort Gruppe zuweisen", result);
      if (result.error) {
        addNewError({
          key: "confuence_group",
          label: "Confluence Standort Gruppe zuweisen",
        });
      } else {
        addNewSuccess({
          key: "confuence_group",
          label: "Confluence Standort Gruppe zuweisen",
        });
      }
      return true;
    }
  };

  const provisionKeeperUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("keeper_user")
    ) {
      try {
        setPending("Erstelle Keeper User...");
        const data = {
          company: ProvisioningData.employeeAttributes.company,
          email: ProvisioningData.employeeAttributes.preferred_mail,
          first_name: ProvisioningData.employeeAttributes.first_name,
          last_name: ProvisioningData.employeeAttributes.last_name,
        };
        let response = await api.post("keeper/user", data);
        let result = await response.data;
        if (result.error) {
          keeperUserCreated.current = false;
          addNewError({ key: "keeper_user", label: "Keeper User erstellen" });
        } else {
          keeperUserCreated.current = true;
          addNewSuccess({ key: "keeper_user", label: "Keeper User erstellen" });
        }
        setNewStep("Keeper User erstellen", result);
        return true;
      } catch (error) {
        keeperUserCreated.current = false;
        addNewError({ key: "keeper_user", label: "Keeper User erstellen" });
      }
    }
  };

  const assignKeeperTeams = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("keeper_teams")
    ) {
      try {
        let errors = false;
        let keeperTeams = ProvisioningData.employeeAttributes.keeper_teams;
        if (!Array.isArray(keeperTeams)) {
          keeperTeams = JSON.parse(keeperTeams);
        }
        for (const keeperTeam of keeperTeams) {
          if (abortFlag.current) {
            return;
          }
          if (
            !plannedTasksArray.current.includes(`keeper_team_${keeperTeam}`)
          ) {
            continue;
          }
          setPending(`Weise Keeper Team "${keeperTeam}" zu...`);
          const data = {
            tenant: ProvisioningData.employeeAttributes.location,
            user: ProvisioningData.employeeAttributes.preferred_mail,
            team: keeperTeam,
          };
          let groupIdsResponse = await api.post("keeper/user/teams", data);
          let responseData = await groupIdsResponse.data;
          setNewStep(`Keeper Team ${keeperTeam} zuweisen`, responseData);
          if (responseData.error) {
            errors = true;
            addNewError({
              key: `keeper_team_${keeperTeam}`,
              label: `Keeper Team ${keeperTeam} zuweisen`,
            });
          } else {
            addNewSuccess({
              key: `keeper_team_${keeperTeam}`,
              label: `Keeper Team ${keeperTeam} zuweisen`,
            });
          }
        }
        if (errors) {
          addNewError({ key: "keeper_teams", label: "Keeper Teams zuweisen" });
        } else {
          addNewSuccess({
            key: "keeper_teams",
            label: "Keeper Teams zuweisen",
          });
        }
      } catch (error) {
        addNewError({ key: "keeper_teams", label: "Keeper Teams zuweisen" });
      }
    }
  };

  const provisionSalesPipedriveUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("pipedrive_sales_user")
    ) {
      setPending("Erstelle Pipedrive User im Tenant Vertrieb...");
      const data = {
        email: ProvisioningData.employeeAttributes.preferred_mail,
        first_name: ProvisioningData.employeeAttributes.first_name,
        last_name: ProvisioningData.employeeAttributes.last_name,
        tenant: "sales",
      };
      let response = await api.post("pipedrive/user", data);
      let result = await response.data;
      setNewStep("Pipedrive User im Tenant Vertrieb erstellen", result);
      if (result.error) {
        addNewError({
          key: "pipedrive_sales_user",
          label: "Pipedrive User im Tenant Vertrieb erstellen",
        });
      } else {
        addNewSuccess({
          key: "pipedrive_sales_user",
          label: "Pipedrive User im Tenant Vertrieb erstellen",
        });
      }
      return true;
    }
  };

  const provisionFranchisePipedriveUser = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("pipedrive_franchise_user")
    ) {
      setPending("Erstelle Pipedrive User im Tenant Franchisemanagement...");
      const data = {
        email: ProvisioningData.employeeAttributes.preferred_mail,
        first_name: ProvisioningData.employeeAttributes.first_name,
        last_name: ProvisioningData.employeeAttributes.last_name,
        tenant: "franchise",
      };
      let response = await api.post("pipedrive/user", data);
      let result = await response.data;
      setNewStep(
        "Pipedrive User im Tenant Franchisemanagement erstellen",
        result
      );
      if (result.error) {
        addNewError({
          key: "pipedrive_franchise_user",
          label: "Pipedrive User im Tenant Franchisemanagement erstellen",
        });
      } else {
        addNewSuccess({
          key: "pipedrive_franchise_user",
          label: "Pipedrive User im Tenant Franchisemanagement erstellen",
        });
      }
      return true;
    }
  };

  const provisionSAPUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("sap_user")) {
      setPending("Frage User beim SAP Support Team an...");
      const data = {
        email: ProvisioningData.employeeAttributes.preferred_mail,
        company: ProvisioningData.employeeAttributes.company,
        ticketnumber: ProvisioningData.ticketnumber,
      };
      let response = await api.post("sap/users", data);
      let result = await response.data;
      setNewStep("SAP User anfragen", result);
      if (result.error) {
        addNewError({ key: "sap_user", label: "SAP User anfragen" });
      } else {
        addNewSuccess({ key: "sap_user", label: "SAP User anfragen" });
      }
      return true;
    }
  };

  const provisionRMMUser = async () => {
    if (!abortFlag.current && plannedTasksArray.current.includes("rmm_user")) {
      setPending("Frage RMM User beim SMS Support Team an...");
      const data = {
        email: ProvisioningData.employeeAttributes.preferred_mail,
        ticket_number: ProvisioningData.ticketnumber,
        company: ProvisioningData.employeeAttributes.company,
        ref_employee: ProvisioningData.employeeAttributes.ref_employee,
        employment_date:
          ProvisioningData.employeeAttributes.employment_date.format(
            "DD.MM.YYYY"
          ),
      };
      let response = await api.post("notifications/rmm/users", data);
      let result = await response.data;
      setNewStep("RMM User anfragen", result);
      if (result.error) {
        addNewError({ key: "rmm_user", label: "RMM User anfragen" });
      } else {
        addNewSuccess({ key: "rmm_user", label: "RMM User anfragen" });
      }
      return true;
    }
  };

  const updateAutotaskTicket = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("autotask_ticket")
    ) {
      setPending("Aktualisiere Autotask Ticket...");
      let succesfulTaskKeys = completedTasks.current.map((task) => task.key);
      let succesfulTaskLabels = completedTasks.current.map(
        (task) => task.label
      );
      let failedTaskLabels = failedTasks.current.map((task) => task.label);

      const data = {
        ticketnumber: ProvisioningData.ticketnumber,
        completedTaskKeys: succesfulTaskKeys,
        completedTaskLabels: succesfulTaskLabels,
        failedTaskLabels: failedTaskLabels,
      };
      let response = await api.patch("autotask/ticket", data);
      let result = await response.data;
      if (result.error) {
        addNewError({
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        });
      } else {
        addNewSuccess({
          key: "autotask_ticket",
          label: "Autotask Ticket aktualisieren",
        });
      }
      setNewStep("Autotask Ticket aktualisieren", result);
    }
  };

  const notifyMarketing = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("email_marketing")
    ) {
      setPending(
        "Versende Benachrichtigung an Marketing & Franchisemenagement..."
      );
      const name =
        ProvisioningData.employeeAttributes.first_name +
        " " +
        ProvisioningData.employeeAttributes.last_name;
      const data = {
        name: name,
        tenant: ProvisioningData.employeeAttributes.company,
        department: ProvisioningData.employeeAttributes.department,
        date: ProvisioningData.employeeAttributes.employment_date.format(
          "DD.MM.YYYY"
        ),
        ticket: ProvisioningData.ticketnumber,
        type: "provisioning",
      };
      let response = await api.post("notifications/notifyMarketing", data);
      let result = await response.data;
      setNewStep(
        "Benachrichtigung an Marketing & Franchisemenagement versenden",
        result
      );
      if (result.error) {
        addNewError({
          key: "email_marketing",
          label:
            "Benachrichtigung an Marketing & Franchisemanagement versenden",
        });
      } else {
        addNewSuccess({
          key: "email_marketing",
          label:
            "Benachrichtigung an Marketing & Franchisemanagement versenden",
        });
      }
    }
  };

  const notifyITOS = async () => {
    if (
      !abortFlag.current &&
      plannedTasksArray.current.includes("email_ITOS")
    ) {
      setPending("Versende Benachrichtigung an ITOS...");
      const name =
        ProvisioningData.employeeAttributes.first_name +
        " " +
        ProvisioningData.employeeAttributes.last_name;
      const data = {
        name: name,
        email: ProvisioningData.employeeAttributes.preferred_mail,
        emp_date:
          ProvisioningData.employeeAttributes.employment_date.format(
            "DD.MM.YYYY"
          ),
        ticket: ProvisioningData.ticketnumber,
        department: ProvisioningData.employeeAttributes.department,
      };
      let response = await api.post("notifications/notifyITOS", data);
      let result = await response.data;
      setNewStep("Benachrichtigung an ITOS versenden", result);
      if (result.error) {
        addNewError({
          key: "email_ITOS",
          label: "Benachrichtigung an ITOS versenden",
        });
      } else {
        addNewSuccess({
          key: "email_ITOS",
          label: "Benachrichtigung an ITOS versenden",
        });
      }
    }
  };

  const provisionUser = async () => {
    setProvisioningData({ ...ProvisioningData, status: "running" });
    setNewStep("Benutzereingliederung starten", {
      success_message: "Benutzereingliederung gestartet",
    });

    for (const func of [
      provisionM365User,
      assignM365Licenses,
      assignM365Groups,
      assignM365Mailboxes,
      setCalendarVisibility,
      provisionConfluenceUser,
      assignConfluenceGroup,
      provisionKeeperUser,
      assignKeeperTeams,
      provisionSalesPipedriveUser,
      provisionFranchisePipedriveUser,
      provisionSAPUser,
      provisionRMMUser,
      notifyMarketing,
      notifyITOS,
      updateAutotaskTicket,
    ]) {
      await func();
    }
    setPending(null);

    if (abortFlag.current) {
      saveReport();
      setProvisioningData({ ...ProvisioningData, status: "aborted" });
      setPending(null);
      setNewStep(<strong>Benutzereingliederung abgebrochen</strong>, {
        error: <strong>Die Benutzereingliederung wurde abgebrochen</strong>,
      });

      return;
    } else {
      if (failedTasks.current.length > 0) {
        setNewStep(<strong>Benutzereingliederung abgeschlossen</strong>, {
          success_message: (
            <strong>Die Benutzereingliederung ist beendet</strong>
          ),
        });
      } else {
        setNewStep(<strong>Benutzereingliederung abgeschlossen</strong>, {
          success_message: (
            <strong>
              Die Benutzereingliederung wurde erfolgreich abgeschlossen
            </strong>
          ),
        });
      }
      saveReport();
      setProvisioningData({ ...ProvisioningData, status: "finished" });
    }
  };

  const handleAbortClick = () => {
    abortFlag.current = true;
    setPending("Eingliederung wird abgebrochen...");
  };

  const handleFinishClick = () => {
    setProvisioningData({
      ...ProvisioningData,
      employeeAttributes: "new",
      step: 1,
      status: "new",
    });
  };

  const handleRetryClick = () => {
    plannedTasksArray.current = failedTasks.current.map((task) => task.key);
    abortFlag.current = false;
    setProvisioningData({
      ...ProvisioningData,
      status: "confirmed",
    });
  };

  useEffect(() => {
    if (!tasksPlanned) {
      setPlannedTasks();
    }

    if (
      ProvisioningData.status === "confirmed" &&
      ProvisioningData.action === "provisioning"
    ) {
      provisionUser();
    }
  }, [ProvisioningData.status]);

  return (
    <>
      <Timeline
        style={{ padding: 20 }}
        mode="left"
        pending={pending}
        items={steps}
      />

      {ProvisioningData.status === "running" &&
        ProvisioningData.action === "provisioning" && (
          <Button type="primary" danger onClick={handleAbortClick}>
            Benutzereingliederung abbrechen
          </Button>
        )}
      {ProvisioningData.status === "finished" &&
        ProvisioningData.action === "provisioning" &&
        failedTasks.current.length === 0 && (
          <Button
            className="btn-primary"
            type="primary"
            onClick={handleFinishClick}
            autoFocus={true}
          >
            Eingliederung abschließen
          </Button>
        )}
      {(ProvisioningData.status === "finished" ||
        ProvisioningData.status === "aborted") &&
        ProvisioningData.action === "provisioning" &&
        failedTasks.current.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "15%",
                }}
              >
                <Button
                  className="btn-primary"
                  type="primary"
                  onClick={handleFinishClick}
                  style={{ marginBottom: "15px" }}
                >
                  Eingliederung zunächst abschließen
                </Button>
                <Button onClick={handleRetryClick}>
                  Fehler erneut versuchen
                </Button>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default UserProvisioningProgress;
