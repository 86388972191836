import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import AuthenticationService, { authURL } from "./AuthenticationService";
import api, { adminAPI } from "../utils/useAxios";
import { useIdleTimer } from "react-idle-timer";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { navObjects } from "../static/navObjects";

const DataContext = createContext();
export const AuthProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState();
  const [currentTenant, setCurrentTenant] = useState();
  const initialized = useRef(false);
  const [PowerBiReports, setPowerBiReports] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [idpDetails, setIdpDetails] = useState(null);
  const [msalInstance, setMsalInstance] = useState(null);
  const [filteredNavItems, setFilteredNavItems] = useState([]);
  const [powerBiToken, setPowerBiToken] = useState(null);

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    console.log("User is active");
    setState("Active");
  };

  useEffect(() => {
    if (state === "Idle" && AuthenticationService.isLoggedIn()) {
      console.log(
        "Session expired due to inactivity at " + new Date().toLocaleString(),
        getRemainingTime()
      );
      AuthenticationService.expireSession();
      AuthenticationService.doLogin();
    }
  }, [state]);

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 3600000,
    throttle: 500,
  });

  const checkPermissions = (object, roles) => {
    if (object.permissions.length === 0) {
      return true;
    }
    return roles.some((userPermission) =>
      object.permissions.includes(userPermission)
    );
  };

  const filterNavItems = (items, roles) => {
    let filteredItems = [];

    for (let item of items) {
      if (!checkPermissions(item, roles)) {
        continue;
      }
      let navItem = {
        key: item.path,
        label: item.label,
        icon: item.menuIcon,
        children: item.children ? filterNavItems(item.children, roles) : null,
      };

      filteredItems.push(navItem);
    }
    return filteredItems;
  };

  const getPowerBIReports = async () => {
    const response = await api.get("powerbi/reports");
    let reports = await response.data;
    console.log("response powerbi/reports in authcontext", reports);
    setPowerBiReports(reports);
  };

  const initMsal = async () => {
    const instance = new PublicClientApplication({
      auth: {
        clientId: "76945902-8dce-4215-96df-3549550b8762",
        navigateToLoginRequestUrl: false,
        authority:
          "https://login.microsoftonline.com/26c9572d-47ed-40d0-8687-45a99b8b67d9",
        redirectUri: "/silent-check-sso.html",
      },
      cache: {
        cacheLocation: "localStorage",
      },
    });
    await instance.initialize();
    const savedAccounts = instance.getAllAccounts();
    if (savedAccounts.length > 0) {
      console.log("setting msal active account", savedAccounts[0]);
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }
    instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        instance.setActiveAccount(event.payload.account);
      }
    });
    setMsalInstance(instance);
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const base64ToBlobUrl = (base64) => {
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return URL.createObjectURL(blob);
  };

  const getProfileImageFromIdP = async (idpUserId, idpToken) => {
    axios
      .get(
        `https://graph.microsoft.com/v1.0/users/${idpUserId}/photos/64x64/$value`,
        {
          headers: {
            Authorization: `Bearer ${idpToken}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const idpImageUrl = URL.createObjectURL(response.data);
        setImageUrl(idpImageUrl);
        blobToBase64(response.data).then((base64) => {
          const storedIdpImage = {
            idpImage: base64,
            accountId: idpUserId,
          };
          localStorage.setItem("idpImage", JSON.stringify(storedIdpImage));
        });
      })
      .catch((error) => {
        setImageUrl("fallback");
      });
  };

  const initialize = async () => {
    let msp = "";
    api.get("auth/msp").then((response) => {
      setCurrentTenant(response.data);
    });
    const parsedToken = AuthenticationService.getParsedToken();

    if (filteredNavItems.length === 0) {
      setFilteredNavItems(
        filterNavItems(navObjects, parsedToken.realm_access.roles)
      );
    }
    if (
      parsedToken.realm_access.roles.includes("controllingPowerBI.Read") ||
      parsedToken.realm_access.roles.includes("controllingPowerBI.ReadWrite") ||
      parsedToken.realm_access.roles.includes("ownDashboards.Read")
    ) {
      getPowerBIReports();
    }
    try {
      let identityProvider = null;
      let idpUserId = null;
      let idpToken = null;
      let parsedIdpToken = null;
      await api.get(`auth/identity_provider`).then((response) => {
        identityProvider = response.data[0];
      });
      const idPResponse = await adminAPI.get(
        `broker/${identityProvider.identityProvider}/token`
      );
      const idpData = await idPResponse.data;
      idpToken = idpData.access_token;
      parsedIdpToken = jwtDecode(idpToken);
      idpUserId = parsedIdpToken.oid;
      initMsal(parsedIdpToken.appid, parsedIdpToken.tid);
      setIdpDetails({
        tenantId: parsedIdpToken.tid,
        clientId: parsedIdpToken.appid,
      });
      const storedIdpImage = localStorage.getItem("idpImage");
      if (storedIdpImage) {
        const { idpImage, accountId } = JSON.parse(storedIdpImage);
        if (accountId === idpUserId) {
          const idpImageUrl = base64ToBlobUrl(idpImage);
          setImageUrl(idpImageUrl);
        } else {
          getProfileImageFromIdP(idpUserId, idpToken);
        }
      } else {
        getProfileImageFromIdP(idpUserId, idpToken);
      }
    } catch (error) {
      console.log(error);
    }

    if (!parsedToken) {
      return;
    }
    const account = {
      username: parsedToken.preferred_username,
      first_name: parsedToken.given_name,
      last_name: parsedToken.family_name,
      full_name: parsedToken.name,
      email: parsedToken.email,
      roles: parsedToken.realm_access.roles,
      msp: msp,
    };
    initialized.current = true;
    setCurrentAccount(account);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <DataContext.Provider
      value={{
        currentAccount: currentAccount,
        setCurrentAccount: setCurrentAccount,
        authenticated: AuthenticationService.isLoggedIn(),
        currentTenant: currentTenant,
        imageUrl: imageUrl,
        idpDetails: idpDetails,
        msalInstance: msalInstance,
        PowerBiReports: PowerBiReports,
        getPowerBIReportParams: getPowerBIReports,
        filteredNavItems: filteredNavItems,
        keycloakInstance: AuthenticationService.keycloakInstance,
        initialized: initialized.current,
        powerBiToken: powerBiToken,
        setPowerBiToken: setPowerBiToken,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useAuthContext = () => useContext(DataContext);
