import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import {
  Button,
  Space,
  Form,
  Spin,
  Table,
  Select,
  Typography,
  Modal,
  Input,
  message,
  Tabs,
  Popconfirm,
  Divider,
  Transfer,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import api from "../utils/useAxios";
import AddIcon from "@mui/icons-material/Add";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import useAuthorized from "../auth/useAuthorized";
const { Title } = Typography;

const Departments = () => {
  const [retrievingData, setRetrievingData] = useState(false);
  const [retrievingTransferData, setRetrievingTransferData] = useState(false);
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [rows, setRows] = useState([]);
  const [systemEntities, setSystemEntities] = useState([]);
  const [systems, setSystems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departmentAssociations, setDepartmentAssociations] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openPatchModal, setOpenPatchModal] = useState(false);
  const [tobePatched, setTobePatched] = useState({ id: null, name: null });
  const [form] = Form.useForm();
  const [patchForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [modalSelectValues, setModalSelectValues] = useState();
  const { currentTenant } = useAuthContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [componentLabels, setComponentLabels] = useState(
    labels.data.departments
  );
  const canWrite = useAuthorized([
    "customersData.ReadWrite",
    "mspsData.ReadWrite",
  ]);

  const getDepartments = async () => {
    try {
      let response = await api.get("departments", {
        params: {
          company: company.id,
          verbose: true,
        },
      });
      let departments = await response.data;
      setRows(departments);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const getSystemEntities = async () => {
    try {
      let response = await api.get("system_entities", {
        params: {
          company: company.id,
        },
      });
      let systemEntities = await response.data;
      setSystemEntities(systemEntities);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const getEmployees = async () => {
    try {
      let response = await api.get("employees", {
        params: {
          company: company.id,
        },
      });
      let employees = await response.data;
      setEmployees(employees);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const getSystems = async () => {
    try {
      let response = await api.get("systems", {
        params: {
          company: company.id,
        },
      });
      let systems = await response.data;
      setSystems(systems);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
  };

  const determineCompanies = () => {
    if (scope === "customers") {
      setCompanies(currentTenant.managed_companies);
      setCompany(null);
    } else if (scope === "msps") {
      setCompanies(
        currentTenant.managed_msps.filter(
          (msp) => msp.id !== currentTenant.own_company.id
        )
      );
      setCompany(null);
    } else {
      setCompany(currentTenant.own_company);
    }
  };

  const getData = async () => {
    if (!company) {
      setRows([]);
      return;
    }
    setRetrievingData(true);
    try {
      Promise.all([
        getSystemEntities(),
        getEmployees(),
        getSystems(),
        getDepartments(),
      ])
        .then((results) => {
          setRetrievingData(false);
        })
        .catch((error) => {
          messageApi.error(error);
        });
    } catch (error) {
      messageApi.error(error.response.data.message);
      setRetrievingData(false);
    }
  };

  const getTransferData = async (departmentId = null) => {
    setRetrievingTransferData(true);
    try {
      if (departmentId) {
        const response = await api.get("departments/associations", {
          params: {
            department: departmentId,
          },
        });
        const associations = await response.data;
        const systemEntities = associations.system_entities;
        const employees = associations.employees;
        const systems = associations.systems;
        patchForm.setFieldsValue({
          associatedM365Groups: systemEntities
            .filter((entity) => entity.type.name === "m365_group")
            .map((entity) => entity.id),
          associatedKeeperTeams: systemEntities
            .filter((entity) => entity.type.name === "keeper_team")
            .map((entity) => entity.id),
          associatedSharedMailboxes: systemEntities
            .filter((entity) => entity.type.name === "shared_mailbox")
            .map((entity) => entity.id),
          associatedEmployees: employees.map((employee) => employee.key),
          associatedSystems: systems.map((system) => system.name),
        });
        setDepartmentAssociations(associations);
      }
    } catch (error) {}
    setRetrievingTransferData(false);
  };

  const openDepartmentModal = (department) => {
    getTransferData(department.id);
    setTobePatched(department);
    setOpenPatchModal(true);
    patchForm.setFieldsValue({
      name: department.name,
      company: department.company.name,
    });
  };

  const resetData = () => {
    setRows([]);
    setSystemEntities([]);
    setEmployees([]);
    setSystems([]);
  };

  useEffect(() => {
    getData();
  }, [company]);

  useEffect(() => {
    if (currentTenant) {
      companyForm.resetFields();
      determineCompanies();
      resetData();
    }
  }, [scope, currentTenant]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <a onClick={() => openDepartmentModal(record)}>{text}</a>
      ),
    },
    {
      title: "Firma",
      dataIndex: ["company", "name"],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.company.startsWith(value),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.company.localeCompare(b.company),
    },
  ];

  const handleCompanyChange = (value) => {
    const selectedCompany = companies.find((company) => company.id === value);
    if (scope === "msps") {
      setCompany(selectedCompany.own_company);
    } else {
      setCompany(selectedCompany);
    }
  };

  const handleCreateClick = () => {
    form.resetFields();
    form.setFieldValue("company", company);
    getTransferData();
    setOpenCreateModal(true);
  };

  const handleCancel = () => {
    form.resetFields();
    patchForm.resetFields();
    setOpenCreateModal(false);
    setOpenPatchModal(false);
  };

  const onCreateFinish = async (values) => {
    const systemEntityIds = []
      .concat(values.associatedM365Groups || [])
      .concat(values.associatedKeeperTeams || [])
      .concat(values.associatedSharedMailboxes || []);
    const employees = values.associatedEmployees || [];
    const systems = values.associatedSystems || [];
    const payload = {
      name: values.name,
      company: company.id,
      shared_entities_ids: systemEntityIds,
      employees: employees,
      systems: systems,
    };
    try {
      const result = await api.post("departments", payload);
      messageApi.success(result.data.message);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setOpenCreateModal(false);
    form.resetFields();
    getData();
  };

  const onPatchFinish = async (values) => {
    const systemEntityIds = []
      .concat(
        values.associatedM365Groups ||
          departmentAssociations?.system_entities
            .filter((entity) => entity.type.name === "m365_group")
            .map((entity) => entity.id) ||
          []
      )
      .concat(
        values.associatedKeeperTeams ||
          departmentAssociations?.system_entities
            .filter((entity) => entity.type.name === "keeper_team")
            .map((entity) => entity.id) ||
          []
      )
      .concat(
        values.associatedSharedMailboxes ||
          departmentAssociations?.system_entities
            .filter((entity) => entity.type.name === "shared_mailbox")
            .map((entity) => entity.id) ||
          []
      );
    const employees =
      values.associatedEmployees ||
      departmentAssociations?.employees.map((employee) => employee.email) ||
      [];
    const systems =
      values.associatedSystems ||
      departmentAssociations?.systems.map((system) => system.name) ||
      [];
    const payload = {
      department: tobePatched.id,
      department_name: values.name,
      system_entities_ids: systemEntityIds,
      employees: employees,
      systems: systems,
    };
    try {
      const result = await api.patch("departments", payload);
      messageApi.success(result.data.message);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setDepartmentAssociations(null);
    setOpenPatchModal(false);
    patchForm.resetFields();
    getData();
  };

  const onDelete = async () => {
    try {
      const result = await api.delete("departments", {
        params: {
          department: tobePatched.id,
        },
      });
      messageApi.success(result.data.message);
    } catch (error) {
      messageApi.error(error.response.data.message);
    }
    setOpenPatchModal(false);
    patchForm.resetFields();
    getData();
  };

  const filterOption = (input, option) => {
    return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const DepartmentTransfer = ({ type, departmentTargetKeys = [] }) => {
    let label = "";
    let name = "";
    let titles = [];
    let dataSource = [];
    if (type === "m365_group") {
      label = "M365 Gruppen";
      name = "associatedM365Groups";
      titles = ["Verfügbare M365-Gruppen", "Zugeordnete M365-Gruppen"];
      dataSource = systemEntities.filter(
        (entity) => entity.type.name === "m365_group"
      );
    }
    if (type === "keeper_team") {
      label = "Keeper Teams";
      name = "associatedKeeperTeams";
      titles = ["Verfügbare Keeper Teams", "Zugeordnete Keeper Teams"];
      dataSource = systemEntities.filter(
        (entity) => entity.type.name === "keeper_team"
      );
    }
    if (type === "shared_mailbox") {
      label = "Geteilte Postfächer";
      name = "associatedSharedMailboxes";
      titles = [
        "Verfügbare Geteilte Postfächer",
        "Zugeordnete Geteilte Postfächer",
      ];
      dataSource = systemEntities.filter(
        (entity) => entity.type.name === "shared_mailbox"
      );
    }
    if (type === "employee") {
      label = "Mitarbeiter";
      name = "associatedEmployees";
      titles = ["Verfügbare Mitarbeiter", "Zugeordnete Mitarbeiter"];
      dataSource = employees;
    }
    if (type === "system") {
      label = "Systeme";
      name = "associatedSystems";
      titles = ["Verfügbare Systeme", "Zugeordnete Systeme"];
      dataSource = systems;
    }
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [targetKeys, setTargetKeys] = useState(departmentTargetKeys);

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
      setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onChange = (nextTargetKeys, direction, moveKeys) => {
      setTargetKeys(nextTargetKeys);
    };

    return (
      <Form.Item label={label} name={name}>
        <Transfer
          dataSource={dataSource}
          showSearch
          disabled={!canWrite}
          onChange={onChange}
          selectedKeys={selectedKeys}
          onSelectChange={onSelectChange}
          targetKeys={targetKeys}
          pagination={true}
          titles={titles}
          listStyle={{
            width: 1000,
            height: 300,
          }}
          filterOption={filterOption}
          style={{ width: "100%", height: 300 }}
          render={(item) => item.title}
        />
      </Form.Item>
    );
  };

  const NewDepartmentTabs = [
    {
      key: "m365Groups",
      label: "M365 Gruppen",
      children: <DepartmentTransfer type="m365_group" />,
    },
    {
      key: "keeperTeams",
      label: "Keeper Teams",
      children: <DepartmentTransfer type="keeper_team" />,
    },
    {
      key: "sharedMailboxes",
      label: "Geteilte Postfächer",
      children: <DepartmentTransfer type="shared_mailbox" />,
    },
    {
      key: "employees",
      label: "Mitarbeiter",
      children: <DepartmentTransfer type="employee" />,
    },
    {
      key: "systems",
      label: "Systeme",
      children: <DepartmentTransfer type="system" />,
    },
  ];

  const PatchDepartmentTabs = [
    {
      key: "m365Groups",
      label: "M365 Gruppen",
      children: (
        <DepartmentTransfer
          type="m365_group"
          departmentTargetKeys={
            departmentAssociations?.system_entities
              ?.filter((entity) => entity.type.name === "m365_group")
              .map((entity) => entity.id) || []
          }
        />
      ),
    },
    {
      key: "keeperTeams",
      label: "Keeper Teams",
      children: (
        <DepartmentTransfer
          type="keeper_team"
          departmentTargetKeys={
            departmentAssociations?.system_entities
              ?.filter((entity) => entity.type.name === "keeper_team")
              .map((entity) => entity.id) || []
          }
        />
      ),
    },
    {
      key: "sharedMailboxes",
      label: "Geteilte Postfächer",
      children: (
        <DepartmentTransfer
          type="shared_mailbox"
          departmentTargetKeys={
            departmentAssociations?.system_entities
              ?.filter((entity) => entity.type.name === "shared_mailbox")
              .map((entity) => entity.id) || []
          }
        />
      ),
    },
    {
      key: "employees",
      label: "Mitarbeiter",
      children: (
        <DepartmentTransfer
          type="employee"
          departmentTargetKeys={
            departmentAssociations?.employees?.map(
              (employee) => employee.value
            ) || []
          }
        />
      ),
    },
    {
      key: "systems",
      label: "Systeme",
      children: (
        <DepartmentTransfer
          type="system"
          departmentTargetKeys={
            departmentAssociations?.systems?.map((system) => system.name) || []
          }
        />
      ),
    },
  ];

  return (
    <div>
      {contextHolder}

      <div className="table">
        <Table
          columns={columns}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          dataSource={rows}
          pagination={{
            total: rows.length || 0,
            showTotal: (total) => `${total} Objekte`,
            defaultPageSize: 10,
            defaultCurrent: 1,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          bordered
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {componentLabels[scope].title}
              </Title>
              <Form
                size="large"
                form={companyForm}
                style={{
                  margin: 10,
                }}
              >
                <Form.Item name="company">
                  <Space direction="horizontal">
                    {scope !== "own" && (
                      <Select
                        autoFocus={true}
                        showSearch
                        placeholder={componentLabels[scope].placeholder}
                        optionFilterProp="children"
                        onChange={handleCompanyChange}
                        style={{
                          width: 320,
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={companies}
                      />
                    )}

                    <Button
                      className="btn-primary"
                      disabled={!canWrite}
                      onClick={handleCreateClick}
                      icon={<AddIcon />}
                    >
                      Neu erstellen
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        />
      </div>
      <Modal
        title={labels.data.departments.formTitleCreate}
        key={labels.data.departments.formTitleCreate}
        open={openCreateModal}
        onCancel={handleCancel}
        width={1200}
        maskClosable={false}
        footer={
          <>
            <Button danger onClick={handleCancel}>
              Abbrechen
            </Button>
            <Button
              form="myForm"
              key="submit"
              htmlType="submit"
              disabled={!canWrite}
            >
              Speichern
            </Button>
          </>
        }
      >
        <Spin
          size="large"
          indicator={<LoadingOutlined className="spinner-style" />}
          spinning={retrievingData}
        >
          <Form
            id="myForm"
            form={form}
            autoComplete="off"
            layout="vertical"
            onFinish={onCreateFinish}
          >
            <Form.Item
              label="Name"
              name="name" // Set the name attribute for the first input
              rules={[
                {
                  required: true,
                  message: "Bitte gib einen Namen an!",
                },
              ]}
            >
              <Input autoComplete="Off" />
            </Form.Item>
            {scope !== "own" && (
              <Form.Item
                label="Firma"
                name="company" // Set the name attribute for the second input
                rules={[
                  {
                    required: true,
                    message: "Du musst ein Unternehmen angeben!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={componentLabels[scope].placeholder}
                  optionFilterProp="children"
                  value={company}
                  style={{
                    width: 320,
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={companies}
                />
              </Form.Item>
            )}
            <Tabs defaultActiveKey="m365Groups" items={NewDepartmentTabs} />
          </Form>
        </Spin>
      </Modal>
      <Modal
        title={labels.data.departments.formTitlePatch}
        key={labels.data.departments.formTitlePatch}
        open={openPatchModal}
        onCancel={handleCancel}
        width={1200}
        maskClosable={false}
        footer={
          <>
            <Popconfirm
              title="Abteilung löschen"
              description={`Bist du sicher, dass du die Abteilung ${tobePatched.name} löschen möchtest?`}
              okText="Ja, endgültig löschen"
              cancelText="Nicht löschen"
              onConfirm={() => onDelete()}
            >
              <Button type="primary" danger disabled={!canWrite}>
                Löschen
              </Button>
            </Popconfirm>

            <Button danger onClick={handleCancel}>
              Abbrechen
            </Button>
            <Button
              form="patchForm"
              key="submit"
              htmlType="submit"
              disabled={!canWrite}
            >
              Speichern
            </Button>
          </>
        }
      >
        <Spin
          size="large"
          indicator={<LoadingOutlined className="spinner-style" />}
          spinning={retrievingTransferData}
        >
          <Form
            id="patchForm"
            form={patchForm}
            autoComplete="off"
            layout="vertical"
            onFinish={onPatchFinish}
          >
            <Form.Item
              label="Name"
              name="name" // Set the name attribute for the first input
              rules={[
                {
                  required: true,
                  message: "Bitte gib einen Namen an!",
                },
              ]}
            >
              <Input autoComplete="Off" />
            </Form.Item>
            {scope !== "own" && (
              <Form.Item
                label="Firma"
                name="company" // Set the name attribute for the second input
                rules={[
                  {
                    required: true,
                    message: "Du musst ein Unternehmen angeben!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Wähle Unternehmen aus"
                  optionFilterProp="children"
                  value={company}
                  disabled={true}
                  style={{
                    width: 320,
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={modalSelectValues}
                />
              </Form.Item>
            )}
            <Tabs defaultActiveKey="m365Groups" items={PatchDepartmentTabs} />
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default Departments;
