import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  Input,
  Popconfirm,
  Modal,
  DatePicker,
} from "antd";
import api from "../utils/useAxios";
import { labels } from "../static/labels";
import dayjs from "dayjs";
import { SiPowerbi } from "react-icons/si";
import AddIcon from "@mui/icons-material/Add";
import locale from "antd/es/date-picker/locale/de_DE";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../auth/AuthContext";
const { Text, Title } = Typography;

const PowerBIAdmin = () => {
  const [rows, setRows] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [patchModalVisible, setPatchModalVisible] = useState(false);
  const [clickedReport, setClickedReport] = useState(null);
  const [reportID, setReportID] = useState(null);
  const [groupID, setGroupID] = useState(null);
  const [createForm] = Form.useForm();
  const [patchForm] = Form.useForm();
  const [inputStatus, setInputStatus] = useState(null);
  const [urlValid, setUrlValid] = useState(null);
  const { getPowerBIReportParams, currentTenant } = useAuthContext();
  const showCreateModal = () => {
    setUrlValid(null);
    setNewModalVisible(true);
  };

  const showPatchModal = (report) => {
    setClickedReport(report);
    patchForm.setFieldsValue(extractInitialValues(report));
    setUrlValid(true);
    setPatchModalVisible(true);
  };

  const handleCancel = () => {
    setUrlValid(null);
    setNewModalVisible(false);
    setPatchModalVisible(false);
    setClickedReport(null);
    createForm.resetFields();
    patchForm.resetFields();
  };

  const deleteReport = async (record) => {
    console.log(record);
    try {
      const response = await api.delete("powerbi/reports", {
        params: { report: record.id },
      });
      getReports();
      messageApi.success("Bericht erfolgreich gelöscht");
    } catch (error) {
      messageApi.error("Fehler beim Löschen des PowerBI Reports");
    }
  };

  const onCreateFinish = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      const dateString = values.date ? values.date.format("YYYY-MM-DD") : null
      const payload = { ...values, date: dateString };
      const response = await api.post("powerbi/reports", payload);
      await getPowerBIReportParams();
      setUrlValid(null);
      setNewModalVisible(false);
      messageApi.success("Bericht erfolgreich verbunden");
      await getReports();
      setLoading(false);
    } catch (error) {
      try {
        messageApi.error(error.response.data.message);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const onPatchFinish = async (values) => {
    console.log("patchValues", values);
    try {
      const dateString = values.date.format("YYYY-MM-DD");
      const payload = { ...values, date: dateString, id: clickedReport.id };
      setLoading(true);
      await api.patch("powerbi/reports", payload);
      await getPowerBIReportParams();
      setUrlValid(null);
      setClickedReport(null);
      setPatchModalVisible(false);
      messageApi.success("Bericht erfolgreich gespeichert");
      await getReports();
      setLoading(false);
    } catch (error) {
      try {
        messageApi.error(error.response.data.message);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      editable: true,
      render: (value, record) => (
        <a
          onClick={() => {
            showPatchModal(record);
          }}
        >
          {value}
        </a>
      ),
    },
    {
      title: "Kategorie",
      dataIndex: ["type", "name"],
      sorter: (a, b) => a.type.name.localeCompare(b.type.name),
      editable: true,
    },
    {
      title: "Berichtszeitraum",
      dataIndex: "date",
      sorter: (a, b) => a.date?.localeCompare(b.date),
      render(value) {
        return value ? dayjs(value).format("YYYY") : null;
      },
    },
    {
      title: "Berichts-ID",
      dataIndex: "report_id",
      sorter: (a, b) => a.report_id.localeCompare(b.report_id),
      editable: true,
    },
  ];

  useEffect(() => {
    getReports();
    getReportTypes();
  }, []);

  const getReports = async () => {
    try {
      const response = await api.get("powerbi/reports");
      const data = await response.data;
      setRows(data);
    } catch (error) {
      messageApi.error("Fehler beim Laden der PowerBI Report-Kategorien");
    }
  };
  const getReportTypes = async () => {
    try {
      const response = await api.get("powerbi/reports/types");
      const data = await response.data;
      const values = data.map((row) => {
        return { ...row, key: row.id };
      });
      setReportTypes(values);
    } catch (error) {
      messageApi.error("Fehler beim Laden der PowerBI Reports");
    }
  };

  const extractInitialValues = (report) => {
    if (report === null) {
      return {};
    }
    const companies = report.companies
    const type = report.type?.id;
    if (report.date) {
      report.date = dayjs(report.date);
    }
    return { ...report, companies, type };
  };

  const PowerBiReportForm = ({ patch = false, initialValues }) => {
    const onURLChange = (e) => {
      validateURL(e.target.value);
    };

    const validateURL = (url) => {
      try {
        const toBeChecked = new URL(url);
        const reportID = url.split("reports/")[1].split("/")[0];
        const groupID = url.split("groups/")[1].split("/")[0];
        setUrlValid(true);
        setGroupID(groupID);
        setReportID(reportID);
        setInputStatus("success");
        createForm.setFieldsValue({
          report_id: reportID,
          group_id: groupID,
        });
        patchForm.setFieldsValue({
          report_id: reportID,
          group_id: groupID,
        });
      } catch (error) {
        setUrlValid(false);
        setInputStatus("error");
        createForm.setFieldsValue({
          report_id: null,
          group_id: null,
        });
      }
    };

    return (
      <Spin
        spinning={loading}
        className="spinner-style"
        indicator={<LoadingOutlined className="spinner-style" />}
        tip="Verbinde Bericht..."
      >
        <Form
          id={patch ? "patchForm" : "createForm"}
          onFinish={patch ? onPatchFinish : onCreateFinish}
          layout="vertical"
          form={patch ? patchForm : createForm}
          initialValues={initialValues}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Bitte gib einen Namen ein",
              },
            ]}
          >
            <Input placeholder="Bitte gib einen beliebigen Namen ein" />
          </Form.Item>
          <Form.Item
            label="Beschreibung"
            name="description"
            rules={[
              {
                required: true,
                message: "Bitte gib eine Beschreibung ein",
              },
            ]}
          >
            <Input.TextArea placeholder="Bitte gib eine Beschreibung ein" />
          </Form.Item>
          <Form.Item
            label="Sichtbar für"
            name="companies"
            rules={[
              {
                required: true,
                message: "Bitte wähle mindestens einen Standort aus",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Bitte wähle mindestens einen Standort aus"
              options={currentTenant?.managed_msps}
              onChange={(value) => console.log(value)}
            />
          </Form.Item>
          <Form.Item
            label="Kategorie"
            name="type"
            rules={[
              {
                required: true,
                message: "Bitte Kategorie auswählen",
              },
            ]}
          >
            <Select
              placeholder="Bitte wähle eine Kategorie aus"
              options={reportTypes}
              onChange={(value) => console.log(value)}
            />
          </Form.Item>
          <Form.Item
            label="Berichtszeitraum"
            name="date"
          >
            <DatePicker
              picker="year"
              locale={locale}
              format={"YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            rules={[
              {
                required: true,
                message: "Bitte gib eine URL ein",
              },
            ]}
          >
            <Input
              placeholder="Bitte kopiere hier die URL des Berichts hinein"
              onChange={onURLChange}
              status={inputStatus}
              id="url"
              style={{
                borderColor: urlValid && "green",
              }}
              suffix={
                urlValid === true ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : urlValid === false ? (
                  <CloseCircleOutlined style={{ color: "red" }} />
                ) : null
              }
            />
          </Form.Item>

          <Form.Item
            label="Berichts-ID"
            name="report_id"
            value={reportID}
            rules={[
              {
                required: true,
                message: "Bitte gib eine Berichts-ID ein",
              },
            ]}
          >
            <Input
              placeholder="Wird anhand der URL automatisch ausgefüllt"
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label="Gruppen-ID"
            name="group_id"
            rules={[
              {
                required: true,
                message: "Bitte gib eine Gruppen-ID ein",
              },
            ]}
          >
            <Input
              placeholder="Wird anhand der URL automatisch ausgefüllt"
              disabled={true}
            />
          </Form.Item>
        </Form>
      </Spin>
    );
  };

  return (
    <div>
      {contextHolder}

      <div className="table">
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {labels.controlling.powerBI.tableHeader}
              </Title>
              <Form
                size="large"
                style={{
                  margin: 10,
                }}
              >
                <Form.Item>
                  <Space direction="horizontal">
                    <Button
                      className="btn-primary"
                      onClick={showCreateModal}
                      icon={<AddIcon />}
                    >
                      {" "}
                      Neuen Bericht verbinden
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
          bordered
          rowKey="id"
          columns={columns}
          dataSource={rows}
        />
      </div>

      <Modal
        title="Neuen Power BI Bericht verbinden"
        open={newModalVisible}
        onCancel={handleCancel}
        footer={
          <>
            <Button danger onClick={handleCancel} disabled={loading}>
              Abbrechen
            </Button>
            <Button
              form="createForm"
              key="submit"
              htmlType="submit"
              disabled={loading}
            >
              Speichern
            </Button>
          </>
        }
      >
        <PowerBiReportForm
          initialValues={{ companies: [currentTenant?.own_company] }}
        />
      </Modal>
      <Modal
        title="Power BI Bericht bearbeiten"
        open={patchModalVisible}
        onCancel={handleCancel}
        footer={
          <>
            <Popconfirm
              title="Verbindung löschen"
              description={`Bist du sicher, dass du diese Verbindung löschen möchtest?`}
              okText="Ja, endgültig löschen"
              cancelText="Nicht löschen"
              onConfirm={() => {
                deleteReport(clickedReport);
                handleCancel();
              }}
            >
              <Button type="primary" danger disabled={loading}>
                Löschen
              </Button>
            </Popconfirm>

            <Button
              form="patchForm"
              key="submit"
              htmlType="submit"
              disabled={loading}
            >
              Speichern
            </Button>
          </>
        }
      >
        <PowerBiReportForm
          initialValues={extractInitialValues(clickedReport)}
          patch={true}
        />
      </Modal>
    </div>
  );
};

export default PowerBIAdmin;
