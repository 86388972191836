import React, { useState, useEffect } from "react";
import "../App.css";
import { Carousel, Card, Typography, Spin } from "antd";
import { useAuthContext } from "../auth/AuthContext";
import useAuthorized from "../auth/useAuthorized";
import PowerBI from "../components/PowerBI";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;

const NewHomePage = () => {
  const { PowerBiReports, currentTenant, initialized, currentAccount } =
    useAuthContext();
  const canSeeDashboard = useAuthorized([
    "ownDashboards.Read",
    "controllingPowerBI.Read",
  ]);
  const location = useLocation();
  const [powerBiReport, setPowerBiReport] = useState(null);
  const [loading, setLoading] = useState(true);

  const contentStyle = {
    height: "250px",
    color: "#fff",
    lineHeight: "250px",
    textAlign: "center",
    background: "#669ca3ff",
    margin: "0 8px",
    borderRadius: 4,
  };

  const FeatureCarousel = () => {
    return (
      <Card
        title="Implementierte und geplante Features"
        className="home-card"
        styles={{
          header: {
            background: "#b9e4dfff",
          },
        }}
      >
        <Carousel
          autoplay
          autoplaySpeed={4000}
          speed={1500}
          style={{ borderRadius: 10 }}
        >
          <div>
            <h2 style={contentStyle}>
              Automatisierte Ein- und Ausgliederungen für Mitarbeiter
              durchführen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Zentrale Zuweisung von M365 Gruppen, Postfächern und Keeper-Teams
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Lizenzkosten aller einsnulleins Standorte einsehen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Automatisches Updaten von Lizenzkosten pro Standort
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Automatische Prüfung IT.Läuft. Verträge
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Monatliche Meldung aktueller Franchise-Partner an einsnulleins
              Lieferanten
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Software-Accounts von Mitarbeitern prüfen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>Microsoft Power BI Dasboards</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Administration von Microsoft M365</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Verwaltung von Keeper MSP</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Mandantenfähigkeit</h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Geplant: Einsehen von offenen Pipedrive-Deals
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Geplant: Pipedrive-Besuchsberichte schreiben
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>
              Geplant: Offene Autotask-Tickets einsehen
            </h2>
          </div>
          <div>
            <h2 style={contentStyle}>Geplant: Autotask-Ticket erstellen</h2>
          </div>
          <div>
            <h2 style={contentStyle}>Geplant: Managed-Service Kalkulator</h2>
          </div>

          <div>
            <h2 style={contentStyle}>
              Geplant: Offene Rechnungen & Angebote aus SAP
            </h2>
          </div>
        </Carousel>
      </Card>
    );
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Spin
          spinning={true}
          indicator={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingOutlined
                className="spinner-style"
                style={{ fontSize: 50 }}
              />
              <h6 className="spinner-style" style={{ whiteSpace: "nowrap" }}>
                Initialisiere...
              </h6>
            </div>
          }
        />
      </div>
    );
  };

  const determineContent = () => {
    if (canSeeDashboard) {
      const companyName =
        currentTenant?.own_company.name.split("einsnulleins ")[1];
      const searchString = `${companyName} Dashboard`;
      let foundReport = PowerBiReports.find(
        (report) => report.label === searchString
      );
      if (!foundReport) {
        foundReport = PowerBiReports.find(
          (report) =>
            report.report_id === "26877b72-5813-45b3-8e7c-9c1653b016c0"
        );
      }
      if (foundReport) {
        console.log("foundReport", foundReport);
        setPowerBiReport(foundReport);
      }
    }
  };

  useEffect(() => {
    console.log("authorized", canSeeDashboard);
    console.log("currentAccount", currentAccount);
    if (currentTenant && PowerBiReports && initialized) {
      determineContent();
      setLoading(false);
    }
  }, [
    currentAccount,
    PowerBiReports,
    currentTenant,
    location,
    canSeeDashboard,
  ]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "85vh" }}>
      <div className="home-header">
        <Title level={1} style={{ marginTop: -5 }}>
          Herzlich Willkommen im einsnulleins Hub
        </Title>
        <Title level={4} style={{ marginTop: -10 }}>
          Das zentrale Tool des einsnulleins Franchise für Administration,
          Verwaltung, Vertrieb und vieles mehr
        </Title>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {loading ? (
          <LoadingScreen />
        ) : canSeeDashboard ? (
          <div style={{ flex: 1, display: "flex" }}>
            <PowerBI showPanel={false} providedReport={powerBiReport} />
          </div>
        ) : (
          <FeatureCarousel />
        )}
      </div>
    </div>
  );
};

export default NewHomePage;
