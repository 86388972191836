import React, { useState, useEffect } from "react";
import PowerBI from "../components/PowerBI";
import { useAuthContext } from "../auth/AuthContext";

const PowerBIDashboardsPage = ({ panelVisible = true }) => {
  const [report, setReport] = useState(null);
  const { PowerBiReports, currentTenant, initialized } = useAuthContext();

  const determineParameters = () => {
    if (!panelVisible) {
      const companyName =
        currentTenant?.own_company.name.split("einsnulleins ")[1];
      const searchString = `${companyName} Dashboard`;
      const foundReport = PowerBiReports.find(
        (report) => report.label === searchString
      );
      if (foundReport) {
        console.log("Found report", foundReport);
        setReport(foundReport);
      }
    }
  };

  useEffect(() => {
    if (currentTenant && PowerBiReports && initialized) {
      determineParameters();
    }
  }, [PowerBiReports, currentTenant, initialized]);
  return panelVisible ? (
    <PowerBI />
  ) : (
    <PowerBI showPanel={false} providedReport={report} />
  );
};

export default PowerBIDashboardsPage;
