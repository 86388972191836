import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Space,
  Form,
  AutoComplete,
  Select,
  Spin,
  message,
  Table,
  Typography,
  Popconfirm,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
const { Title } = Typography;

const AccountLicenses = () => {
  const [accounts, setAccounts] = useState([]);
  const [rows, setRows] = useState([]);
  const [email, setEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [filters, setFilters] = useState([]);
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentAccount, currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);

  const [form] = Form.useForm();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [selectedCompany, setSelectedCompany] = useState(
    scope === "own" ? currentTenant?.name : null
  );

  useEffect(() => {
    if (currentTenant) {
      form.resetFields();
      setEmail("");
      if (scope === "msps") {
        setCompanies(currentTenant.managed_msps);
      } else if (scope === "own") {
        setSelectedCompany(currentTenant.name);
        getEmployees(currentTenant.own_company.id);
      }
      setRetrievingData(false);
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (email !== "") {
      getData();
    } else {
      setRows([]);
    }
  }, [email]);

  const getEmployees = async (forCompany = false) => {
    try {
      setRetrievingData(true);
      const response = await api.get("employees", {
        params: { company: forCompany ? forCompany : selectedCompany },
      });
      const emails = await response.data;
      setAccounts(emails);
    } catch (error) {
      console.log(error);
    }

    setRetrievingData(false);
  };

  const columns = [
    {
      title: "System",
      dataIndex: ["license", "related_system", "name"],
      filterMode: "tree",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.license.name.localeCompare(b.license.name),
    },
    {
      title: "Lizenz",
      dataIndex: ["license", "name"],
      filterMode: "tree",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.license.name.localeCompare(b.license.name),
    },
    {
      title: "System-ID",
      dataIndex: "id_software",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["ascend", "descend"],
    },
  ];

  const handleCompanyChange = (value) => {
    const selectedCompany = companies.find((company) => company.id === value);
    if (scope === "msps") {
      setSelectedCompany(selectedCompany.own_company.id);
      getEmployees(selectedCompany.own_company.id);
    } else {
      setSelectedCompany(selectedCompany.id);
      getEmployees(selectedCompany.id);
    }
  };

  const handleAccountChange = (value) => {
    setRows([]);
    setEmail(value || ""); // Use an empty string as the default value
  };

  const getData = async () => {
    try {
      if (email.includes("@")) {
        const response = await api.get("employees/licenses", {
          params: { employee: email },
        });
        setRows(response.data);
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Daten");
    }
  };

  return (
    <Spin
      spinning={retrievingData}
      delay={500}
      size="large"
      tip="Rufe Daten ab..."
      className="spinner-style"
      indicator={<LoadingOutlined className="spinner-style" />}
    >
      <>
        <div className="table">
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            bordered
            title={() => (
              <div className="table-header">
                <Title
                  level={3}
                  style={{
                    marginTop: -10,
                    marginBottom: 10,
                  }}
                >
                  {labels.licenses.accountLicenses[scope].title}
                </Title>
                <Form
                  size="large"
                  form={form}
                  style={{
                    margin: 10,
                  }}
                >
                  <Space direction="horizontal">
                    <Form.Item name="company">
                      {scope !== "own" && (
                        <Select
                          autoFocus={true}
                          showSearch
                          placeholder={
                            labels.licenses.accountLicenses[scope].placeholder
                          }
                          optionFilterProp="children"
                          onChange={handleCompanyChange}
                          style={{
                            width: 320,
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={companies}
                        />
                      )}
                    </Form.Item>
                    <Form.Item name="email">
                      <AutoComplete
                        style={{
                          width: 320,
                        }}
                        disabled={selectedCompany === null}
                        options={accounts}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleAccountChange}
                        placeholder="Gib einen Namen ein"
                      />
                    </Form.Item>
                    {contextHolder}
                  </Space>
                </Form>
              </div>
            )}
          />
        </div>
      </>
    </Spin>
  );
};

export default AccountLicenses;
