import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  Popconfirm,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation } from "react-router-dom";
import { labels } from "../static/labels";
import SyncIcon from "@mui/icons-material/Sync";
import dayjs from "dayjs";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import ReportIcon from "@mui/icons-material/Report";
import ExportReports from "./ExportReports";
const { Text, Title } = Typography;

const ContractCheck = () => {
  const [rows, setRows] = useState([]);
  const [company, setCompany] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentTenant } = useAuthContext();
  const [companies, setCompanies] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [contractType, setContractType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [filters, setFilters] = useState({});

  const contractTypeSelectOptions = [
    { value: "all", label: "Alle Verträge" },
    { value: "legacy", label: "Altverträge" },
    { value: "itlaeuft", label: "IT.Läuft." },
    { value: "keeper", label: "Keeper" },
  ];

  useEffect(() => {
    if (currentTenant) {
      setRows([]);
      if (scope == "customers") {
        setCompany(currentTenant.own_company);
      } else if (scope == "msps") {
        const options = currentTenant?.managed_msps;
        console.log(options);
        setCompanies(options);
      }
    }
  }, [currentTenant, scope]);

  useEffect(() => {
    if (company && selectedDate && contractType) {
      getData();
    }
  }, [company, selectedDate, contractType]);

  const columns = [
    {
      title: "Kunde",
      dataIndex: "company",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.company.localeCompare(b.name),
    },
    {
      title: "Vertrag",
      dataIndex: "contract",
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.contract.localeCompare(b.name),
    },
    {
      title: "Berechnete Anzahl",
      dataIndex: "contract_quantity",
      sorter: (a, b) => a.contract_quantity - b.contract_quantity,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Tatsächliche Anzahl",
      dataIndex: "system_quantity",
      sorter: (a, b) => a.system_quantity - b.system_quantity,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return value ? (
          value
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>
              <span style={{ color: "red" }}>Unbekannt</span>
            </strong>
            <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
          </div>
        );
      },
    },
    {
      title: "Differenz",
      dataIndex: "difference",
      sorter: (a, b) => {
        if (a.difference === null) return 1;
        if (b.difference === null) return -1;
        return a.difference - b.difference;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        if (value != null) {
          if (value != 0) {
            return (
              <Text type="danger">
                <strong>{value}</strong>
              </Text>
            );
          } else {
            return <Text type="success">{value}</Text>;
          }
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>
                <span style={{ color: "red" }}>Unbekannt</span>
              </strong>
              <ReportIcon style={{ color: "red", marginLeft: "8px" }} />
            </div>
          );
        }
      },
    },
    {
      title: "Hinweis",
      dataIndex: "error_note",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.error_note.localeCompare(b.error_note),
    },
  ];

  const handleMSPChange = (key, value) => {
    setCompany(value);
  };

  const handleContractTypeChange = (key, value) => {
    setContractType(key);
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const getData = async () => {
    setRetrievingData(true);
    try {
      let msp_id = company.id;
      if (scope === "customers") {
        msp_id = company.own_msp.id;
      }
      const response = await api.get("contracts/check", {
        params: {
          msp: msp_id,
          contractType: contractType,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });
      console.log(response);
      let result = await response.data;
      if (result.length === 0) {
        messageApi.info("Keine Daten vorhanden");
        setRows([]);
        setRetrievingData(false);
        return;
      }
      const data = result.map((obj, index) => ({
        ...obj,
        key: index,
      }));
      setRows(data);
    } catch (error) {
      messageApi.error("Fehler beim Laden der Daten");
      console.error(error);
    }
    setRetrievingData(false);
  };

  const updateData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.post("contracts/check", {
        msp: company.own_msp.id,
        contractType: contractType,
        date: selectedDate.format("YYYY-MM-DD"),
      });
      let result = await response.data;
      if (result.length === 0) {
        messageApi.info("Keine Daten vorhanden");
        setRows([]);
        setRetrievingData(false);
        return;
      }
      const data = result.map((obj, index) => ({
        ...obj,
        key: index,
      }));
      setRows(data);
    } catch (error) {
      console.log(error);
      messageApi.error(error.response.data.message);
    }
    setRetrievingData(false);
  };

  return (
    <>
      <div className="table">
        <Table
          columns={columns}
          dataSource={rows}
          scroll={{
            y: 500,
          }}
          loading={{
            spinning: retrievingData,
            indicator: <LoadingOutlined className="spinner-style" />,
          }}
          bordered
          pagination={{
            total: rows.length || 0,
            showTotal: (total) => `${total} Objekte`,
            defaultPageSize: 10,
            defaultCurrent: 1,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title
                level={3}
                style={{
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                {labels.reports[scope].contractChecks.title}
              </Title>
              <Form
                size="large"
                style={{
                  margin: 10,
                }}
              >
                <Form.Item>
                  <Space direction="horizontal">
                    <ExportReports
                      selectedDate={selectedDate}
                      selectedReportType={contractType}
                    />
                    <Popconfirm
                      title="Daten synchronisieren?"
                      description={`Das Synchronisieren kann, je nach Anzahl der Verträge und Kunden, einige Minuten in Anspruch nehmen!`}
                      okText="Ja, jetzt synchronisieren"
                      cancelText="Abbrechen"
                      onConfirm={() => updateData()}
                    >
                      <Button
                        type="primary"
                        icon={<SyncIcon />}
                        className="btn-primary"
                        disabled={!company || !selectedDate || !contractType}
                      />
                    </Popconfirm>
                    {scope !== "customers" && (
                      <Select
                        autoFocus={true}
                        showSearch
                        placeholder={
                          labels.reports[scope].contractChecks.placeholder
                            .companies
                        }
                        optionFilterProp="children"
                        onChange={handleMSPChange}
                        style={{
                          width: 320,
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={companies}
                      />
                    )}
                    <Select
                      autoFocus={true}
                      showSearch
                      optionFilterProp="children"
                      placeholder="Vertragstyp auswählen"
                      onChange={handleContractTypeChange}
                      style={{
                        width: 250,
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={contractType}
                      options={contractTypeSelectOptions}
                    />
                    <DatePicker
                      onChange={onDateChange}
                      picker="month"
                      locale={locale}
                      format={"MMMM YYYY"}
                      style={{ width: 250 }}
                      value={selectedDate}
                    />
                    {contextHolder}
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default ContractCheck;
