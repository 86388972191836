import React, { useState, useEffect } from "react";
import api from "../utils/useAxios";
import "../App.css";
import {
  Button,
  Table,
  Space,
  Form,
  Spin,
  message,
  Select,
  Typography,
  DatePicker,
  Popconfirm,
} from "antd";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuthContext } from "../auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { labels } from "../static/labels";
import { LoadingOutlined } from "@ant-design/icons";
import useAuthorized from "../auth/useAuthorized";
import locale from "antd/es/date-picker/locale/de_DE";
import dayjs from "dayjs";
const { Text, Title } = Typography;

const KeeperMSPLicenses = () => {
  const [rows, setRows] = useState([]);
  const [msp, setMSP] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [retrievingData, setRetrievingData] = useState(false);
  const { currentTenant } = useAuthContext();
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const scope = searchParams.get("scope");
  const [form] = Form.useForm();
  const canSeeLicenseData = useAuthorized(["licenseData.Read"]);

  const columns = [
    {
      title: "Kunde",
      dataIndex: "customer",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.customer.localeCompare(b.customer),
    },
    {
      title: "MSP",
      dataIndex: "msp",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.msp.localeCompare(b.msp),
    },
    {
      title: "Plan",
      dataIndex: "license",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.license.localeCompare(b.license),
    },

    {
      title: "Aktive User",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ["ascend", "descend"],
    },
    canSeeLicenseData && {
      title: "Einzelkosten",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return parseFloat(value).toFixed(2).replace(".", ",") + " €";
      },
    },
    canSeeLicenseData && {
      title: "Gesamtkosten",
      dataIndex: "total_price",
      sorter: (a, b) => a.total_price - b.total_price,
      sortDirections: ["ascend", "descend"],
      render: (value) => {
        return parseFloat(value).toFixed(2).replace(".", ",") + " €";
      },
    },
  ];

  const filterColumns = () => {
    return columns.filter((obj) => obj);
  };

  const handleSelectChange = (value) => {
    console.log("value", value);
    if (value === "Alle Standorte") {
      setMSP(value);
    } else {
      const msp = currentTenant?.managed_msps.find((msp) => msp.id === value);
      setMSP(msp);
    }
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const getData = async () => {
    setRetrievingData(true);
    try {
      const response = await api.get("keeper/msp/licenses", {
        params: {
          msp: msp.id,
          date: selectedDate.format("YYYY-MM-DD"),
        },
      });

      let data = await response.data;
      setRows(data);
      if (data.length === 0) {
        messageApi.info("Keine Daten für den ausgewählten Zeitraum vorhanden");
      }
    } catch (error) {
      message.error(error.message);
    }
    setRetrievingData(false);
  };

  useEffect(() => {
    setRows([]);
    form.resetFields();
    if (currentTenant) {
      if (scope === "own") {
        setMSP(currentTenant);
      } else {
        setSelectOptions(currentTenant?.managed_msps);
        setMSP(currentTenant);
        form.setFieldsValue({
          company: currentTenant,
        });
      }
    }
  }, [currentTenant]);

  useEffect(() => {
    if (msp && selectedDate) {
      getData();
    }
  }, [msp, selectedDate]);

  return (
    <>
      {contextHolder}
      <Spin
        spinning={retrievingData}
        delay={500}
        size="large"
        tip="Rufe Daten ab..."
        className="spinner-style"
        indicator={<LoadingOutlined className="spinner-style" />}
      >
        <div className="table">
          <Table
            columns={filterColumns()}
            dataSource={rows}
            bordered
            title={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title
                  level={3}
                  style={{
                    marginTop: -10,
                    marginBottom: 10,
                  }}
                >
                  {labels.keeperMSPCustomers.overview[scope].title}
                </Title>
                <Form
                  size="large"
                  form={form}
                  initialValues={{
                    company: msp?.id,
                  }}
                >
                  <Space direction="horizontal">
                    {scope !== "own" && (
                      <Form.Item name="company">
                        <Select
                          autoFocus={true}
                          showSearch
                          disabled={selectOptions?.length > 0 ? false : true}
                          placeholder={
                            labels.keeperMSPCustomers.overview[scope]
                              .placeholder
                          }
                          optionFilterProp="children"
                          onChange={handleSelectChange}
                          style={{
                            width: 320,
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={selectOptions}
                        />
                      </Form.Item>
                    )}

                    <Form.Item>
                      <DatePicker
                        onChange={onDateChange}
                        picker="month"
                        locale={locale}
                        format={"MMMM YYYY"}
                        style={{ width: 200 }}
                        value={selectedDate}
                      />
                    </Form.Item>
                  </Space>
                </Form>
              </div>
            )}
            pagination={false}
            summary={
              canSeeLicenseData
                ? (pageData) => {
                    let totalPrice = 0;
                    pageData.forEach(({ total_price }) => {
                      totalPrice += total_price;
                    });
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={filterColumns().length - 1}
                          >
                            <Text strong>TOTAL</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text strong>
                              {parseFloat(totalPrice)
                                .toFixed(2)
                                .replace(".", ",") + " €"}
                            </Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }
                : null
            }
          />
        </div>
      </Spin>
    </>
  );
};

export default KeeperMSPLicenses;
